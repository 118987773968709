import React from "react"

const NotFoundPage = () => {
  return <div><h1>404</h1>
        <p>
        This page does not exist. <a href="/">Click here to go back to the homepage.</a>
        </p>
        </div>
}
export default NotFoundPage;
